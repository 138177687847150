<template>
    <div
        class="d-flex flex-column justify-content-center container p-lg-0"
        style="margin:3rem auto;"
    >
        <div class="d-flex border register" style="border-radius: 15px;">
            <bg-auth style="width: 65%;" class="bg-auth" />
            <div
                class="d-flex flex-column justify-content-between auth-form"
                style="width:35%;"
            >
                <auth-form
                    header="OTP for password Reset - number"
                    class="auth-form"
                >
                    <div class="mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <mail-icon />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="email"
                                placeholder="E-mail or Phone number"
                                style="border-left-color:white;"
                            />
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="submitStatus == 'ERROR' && !$v.email.required"
                        >
                            Email is required.
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="submitStatus == 'ERROR' && !$v.email.email"
                        >
                            Email must be email.
                        </div>
                    </div>
                    <div>{{ successMsg }}</div>
                    <div
                        class="error invalid-feedback d-block"
                        v-if="submitStatus == 'ERROR' && authError"
                    >
                        {{ authError }}
                    </div>
                    <div class="text-center mx-2 mt-2">
                        <button
                            v-if="!isLoading"
                            @click="forgotPassword"
                            class="w-100 reset-button"
                        >
                            <span style="font-size:1rem">Send Email</span>
                        </button>
                        <button
                            v-else
                            class="btn btn-primary loading-btn w-100"
                            type="button"
                            disabled
                        >
                            <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    </div>
                </auth-form>
            </div>
        </div>
    </div>
</template>

<script>
import BgAuth from '../../components/auth/BgAuth';
import AuthForm from '../../components/auth/AuthForm';
// import Button from '../../components/reusable/Button';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// graphql mutations
import ForgotPassword from '../../graphql/mutations/auth/ForgetPassword.gql';

export default {
    mixins: [validationMixin],

    components: {
        'bg-auth': BgAuth,
        'auth-form': AuthForm,
        // 'auth-button': Button,
    },

    data() {
        return {
            isLoading: false,
            authError: '',
            email: '',
            submitStatus: null,
            successMsg: '',
        };
    },

    validations: {
        email: {
            required,
        },
    },

    methods: {
        forgotPassword() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.isLoading = true;
                this.$apollo
                    .mutate({
                        mutation: ForgotPassword,

                        variables: {
                            email: this.email,
                        },
                    })
                    .then(response => {
                        console.log(response);
                        if (response.data.forgotUserPassword.code == 200) {
                            this.successMsg =
                                response.data.forgotUserPassword.message;

                            this.$router.push({
                                name: 'updateUserPassword',
                                query: {
                                    username: this.email,
                                },
                            });
                        } else {
                            this.submitStatus = 'ERROR';
                            this.authError =
                                response.data.forgotUserPassword.message;
                        }
                        this.isLoading = false;
                    })
                    .catch(errors => {
                        console.log(errors);
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.reset-button {
    background-color: #49bb09;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        opacity: 0.8;
    }
}
.auth-button {
    background-color: #49bb09;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        background-color: var(--color-green);
        color: #fff;
    }
}
.auth-form {
    padding: 1rem 0;
    .key-icon {
        rotate: 180deg;
    }
    .input-icon {
        background: white;
        // border-right-color: 1px solid white;
        padding-right: 0;
        border: 1px solid #0000000d;
    }
}
.input-group-text {
    border-radius: 10px;
}
.form-control {
    border: 1px solid #0000000d;
    border-radius: 10px;
}
::placeholder {
    color: #111111;
    opacity: 0.5;
}
@media (min-width: 280px) and (max-width: 991.98px) {
    .register {
        flex-direction: column;
    }
    .bg-auth {
        width: 100% !important;
    }
    .auth-form {
        width: 100% !important;
    }
}
</style>
